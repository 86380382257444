<template>
    <div class="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid">
        <div class="kt-portlet kt-portlet--mobile" id="tableDiv">
            <div v-if="!showDialogFilters" class="kt-portlet__head kt-portlet__head--lg no-border-radius">
                <app-filter-bar @datesAndFiltersHandler="validateDataPreRequest"
                                :feature="'TRACK'"
                                :is_working_modes="true"
                                :show_vehicle_type="true"
                                :filter_button_is_loading="false"
                                ref="filterBar">
                    <template v-slot:slot-two>
                        <app-button type="secondary"
                                    class="auto-center"
                                    v-if="arrayVehicles.length > 0 && !isLoadingSensorData"
                                    style="background-color: #0D89EC;"
                                    @handleClick="exportCsv">
                            <i class="pi pi-external-link" />
                            {{ $t('AppWorkingModes.Exportar') }}
                        </app-button>
                    </template>
                </app-filter-bar>
            </div>
            <div v-if="showDialogFilters" class="kt-portlet__head kt-portlet__head--lg no-border-radius" style="justify-content: center;">
                <app-button v-show="showDialogFilters" type="primary" class="mx-1 auto-center"
                            @handleClick="displayMaximizable = true">
                    {{ $t('AppWorkingModes.Filtrar') }}
                </app-button>
                <app-button type="secondary"
                            class="auto-center"
                            v-if="arrayVehicles.length > 0 && !isLoadingSensorData"
                            style="background-color: #0D89EC;"
                            @handleClick="exportCsv">
                    <i class="pi pi-external-link" />
                    {{ $t('AppWorkingModes.Exportar') }}
                </app-button>
            </div>
            <Dialog :header="$t('AppWorkingModes.Filtrar')" :visible.sync="displayMaximizable" :containerStyle="{width: '85vw'}" :maximizable="true" :modal="true">
                <app-filter-bar @datesAndFiltersHandler="validateDataPreRequest"
                                :is_col_12="true"
                                :feature="'TRACK'"
                                :is_working_modes="true"
                                :filter_button_is_loading="false"
                                ref="filterBar"/>
            </Dialog>

            <div class="kt-portlet__body no-padding">
                <div v-if="arrayVehicles.length === 0 && !isLoadingSensorData" class="dashboard-container-no-data">
                    <div class="row no-data-container">
                        <h4>{{$t('AppWorkingModes.Sem dados para mostrar, faça a filtragem')}}</h4>
                    </div>
                </div>
                <div v-else-if="isLoadingSensorData" class="dashboard-container-loading">
                    <ProgressSpinner />
                </div>
                <div v-else>
                    <div class="width-vehicle-name">
                        <div v-for="(name, index) in arrayVehiclesName" :key="index" class="vehicle-name"
                            :class="{'vehicle-name-active': index === vehicleSelected, 'bg-vehicle-name': bgColorGetItemByArrayVehicles(index)}"
                            @click="changeVehicleSelected(index)">
                            <div v-if="index === 0" style="display: flex;">
                                <i class="pi pi-cog" style="font-size: 20px; position: relative; bottom: 1px;" />
                            </div>
                            <h4 v-else class="f-bold">{{ name }}</h4>
                        </div>
                    </div>
                    <div :class="{'height-info-config': vehicleSelected === 0, 'height-info' : vehicleSelected > 1, 'height-info-total' : vehicleSelected === 1}" >
                        <AppWorkingModesInfoVehicle
                            v-if="vehicleSelected > 0"
                            :item="getItemByArrayVehicles"
                            :showFilterShiftProp="showFilterShiftProp"
                            :workingModeMoney="workingModeMoney"
                            :idleModeMoney="idleModeMoney"
                            :inconsistencyModeMoney="inconsistencyModeMoney"
                            :maneuveringModeMoney="maneuveringModeMoney"
                            :travelingModeMoney="travelingModeMoney"
                            :offModeMoney="offModeMoney"
                            :waitingDataModeMoney="waitingDataModeMoney"
                            :maintenanceModeMoney="maintenanceModeMoney"
                            :consumptionMoney="consumptionMoney"
                            :showFormatHour="showFormatHour"
                        />
                        <div v-else class="container-config">
                            <div class="container-config-first">
                                <div class="container-costs">
                                    <div style="display: flex; justify-content: center; align-items: center; text-align: center; width: 100%; color: #696969; margin-bottom: 0px;">
                                        <div class="icon-RS">R$</div>
                                        <h4 class="f-bold">CUSTOS</h4>
                                    </div>
                                    <h5 style="text-align: center; width: 100%; color: #696969;">
                                        {{$t('AppWorkingModes.Insira os custos por modo de trabalho')}}
                                    </h5>

                                    <div class="input-money" style="margin: 10px 10px;">
                                        <h5 style="color: #696969;">{{$t('AppWorkingModes.Produzindo')}}</h5>
                                        <div style="width: 100px; height: 3px; background-color: #2CA02C; margin: 0 auto 7px auto; border-radius: 1px;"></div>
                                        <InputNumber v-model="workingModeMoney" :min="0" mode="currency" currency="BRL" :step="0.01"/>
                                    </div>
                                    <div class="input-money" style="margin: 10px 10px;">
                                        <h5 style="color: #696969;">{{$t('AppWorkingModes.Manobrando')}}</h5>
                                        <div style="width: 100px; height: 3px; background-color: #FFAA00; margin: 0 auto 7px auto; border-radius: 1px;"></div>
                                        <InputNumber v-model="maneuveringModeMoney" :min="0" mode="currency" currency="BRL" :step="0.01"/>
                                    </div>
                                    <div class="input-money" style="margin: 10px 10px;">
                                        <h5 style="color: #696969;">{{$t('AppWorkingModes.Deslocando')}}</h5>
                                        <div style="width: 100px; height: 3px; background-color: #1F76B4; margin: 0 auto 7px auto; border-radius: 1px;"></div>
                                        <InputNumber v-model="travelingModeMoney" :min="0" mode="currency" currency="BRL" :step="0.01"/>
                                    </div>
                                    <div class="input-money" style="margin: 10px 10px;">
                                        <h5 style="color: #696969;">{{$t('AppWorkingModes.Ocioso')}}</h5>
                                        <div style="width: 100px; height: 3px; background-color: #D62728; margin: 0 auto 7px auto; border-radius: 1px;"></div>
                                        <InputNumber v-model="idleModeMoney" :min="0" mode="currency" currency="BRL" :step="0.01"/>
                                    </div>
                                    <div class="input-money" style="margin: 10px 10px;">
                                        <h5 style="color: #696969;">{{$t('AppWorkingModes.Desligado')}}</h5>
                                        <div style="width: 100px; height: 3px; background-color: #5E3F20; margin: 0 auto 7px auto; border-radius: 1px;"></div>
                                        <InputNumber v-model="offModeMoney" :min="0" mode="currency" currency="BRL" :step="0.01"/>
                                    </div>
                                    <div class="input-money" style="margin: 10px 10px;">
                                        <h5 style="color: #696969;">{{$t('AppWorkingModes.Aguardando Dados')}}</h5>
                                        <div style="width: 100px; height: 3px; background-color: #FFFF33; margin: 0 auto 7px auto; border-radius: 1px;"></div>
                                        <InputNumber v-model="waitingDataModeMoney" :min="0" mode="currency" currency="BRL" :step="0.01"/>
                                    </div>
                                    <div class="input-money" style="margin: 10px 10px;">
                                        <h5 style="color: #696969;">{{$t('AppWorkingModes.Manutenção')}}</h5>
                                        <div style="width: 100px; height: 3px; background-color: #b507a7; margin: 0 auto 7px auto; border-radius: 1px;"></div>
                                        <InputNumber v-model="maintenanceModeMoney" :min="0" mode="currency" currency="BRL" :step="0.01"/>
                                    </div>
                                    <div class="input-money" style="margin: 10px 10px;">
                                        <h5 style="color: #696969;">{{$t('AppWorkingModes.Incoerência')}}</h5>
                                        <div style="width: 100px; height: 3px; background-color: #FFAEC9; margin: 0 auto 7px auto; border-radius: 1px;"></div>
                                        <InputNumber v-model="inconsistencyModeMoney" :min="0" mode="currency" currency="BRL" :step="0.01"/>
                                    </div>
                                    <div class="input-money" style="margin: 10px 10px;">
                                        <h5 style="color: #696969;">{{$t('AppWorkingModes.Combustível')}}</h5>
                                        <div style="width: 100px; height: 3px; background-color: #000000; margin: 0 auto 7px auto; border-radius: 1px;"></div>
                                        <InputNumber v-model="consumptionMoney" :min="0" mode="currency" currency="BRL" :step="0.01"/>
                                    </div>
                                </div>
                            </div>
                            <div class="container-config-last">
                                <div class="container-shift">
                                    <div style="background-color: white; border-radius: 10px; box-shadow: rgba(0, 0, 0, 0.1) 0px 5px 15px; height: 100%; color: #696969; text-align: center;
                                            display: flex; flex-direction: column; justify-content: center; padding: 0 20px;">
                                        <h4 style="text-align: center; width: 100%; color: #696969; margin-bottom: 20px;" class="f-bold">
                                            {{$t('AppWorkingModes.TURNO')}}
                                        </h4>
                                        <h5 style="text-align: center; width: 100%; color: #696969; margin-bottom: 20px;">
                                            {{$t('AppWorkingModes.Filtre os dados por turno')}}
                                        </h5>
                                        <div>
                                            <SelectButton v-model="showFilterShift" :options="showFilterShiftOptions" optionLabel="name" optionValue="value" multiple />
                                        </div>

                                        <div style="display: flex; flex-direction: column; margin: 20px auto 0 auto;" v-if="showFilterShiftA || showFilterShiftB || showFilterShiftC">
                                            <div style="display: flex;">
                                                <div style="display: flex; flex-direction: column;">
                                                    <label for="startHour">{{$t('AppWorkingModes.Inicio')}}</label>
                                                    <Calendar id="startHour" ref="startHourCalendar" :timeOnly="true" :stepMinute="60" v-model="startHour"/>
                                                </div>

                                                <div style="display: flex; flex-direction: column; margin-left: 15px;">
                                                    <label for="endHour">{{$t('AppWorkingModes.Fim')}}</label>
                                                    <Calendar id="endHour" :timeOnly="true" :stepMinute="60" v-model="endHour"/>
                                                </div>
                                            </div>

                                            <app-button type="secondary" v-if="showFilterShift[0] != showFilterShiftProp[6]" style="width: 70px; margin: 20px auto 0 auto;"
                                                        @handleClick="setDataByOriginalResponse">
                                                {{$t('AppWorkingModes.Filtrar')}}
                                            </app-button>
                                            <app-button type="primary" v-else style="width: 70px; margin: 20px auto 0 auto;"
                                                        @handleClick="setDataByOriginalResponse">
                                                {{$t('AppWorkingModes.Filtrado')}}
                                            </app-button>
                                        </div>
                                    </div>
                                </div>
                                <div class="container-format">
                                    <div style="background-color: white; border-radius: 10px; box-shadow: rgba(0, 0, 0, 0.1) 0px 5px 15px; height: 100%; text-align: center; align-items: center; justify-content: center; display: flex; flex-direction: column;">
                                        <h4 style="text-align: center; width: 100%; color: #696969; margin-bottom: 20px;" class="f-bold">
                                            {{$t('AppWorkingModes.FORMATAÇÃO')}}
                                        </h4>
                                        <h5 style="text-align: center; width: 100%; color: #696969; margin-bottom: 20px;">
                                            {{$t('AppWorkingModes.Escolha um formato para exibir os horários')}}
                                        </h5>
                                        <SelectButton v-model="showFormatHour" :options="formatHourOptions" optionLabel="name" optionValue="value" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import AppFilterBar from '../../common/AppFilterBar';
import AppButton from "../../common/AppButton";
import Dialog from 'primevue/dialog';
import ProgressSpinner from 'primevue/progressspinner';
import Calendar from 'primevue/calendar';
import Checkbox from 'primevue/checkbox';
import SelectButton from 'primevue/selectbutton';
import Button from 'primevue/button/Button';
import InputNumber from 'primevue/inputnumber';
import AppWorkingModesLineChart from './AppWorkingModesLineChart';
import AppWorkingModesPie from './AppWorkingModesPie';
import AppWorkingModesInfoVehicle from './AppWorkingModesInfoVehicle.vue';
import exportJsonToCsv from '@/mixins/JsonToCsvMixin';
import VehiclesService from "../../../services/VehiclesService";
import dateHourFormat from "@/mixins/DateMixin";
import getTimeFromSecondsDurationFormatMixinShowZero from '../../../mixins/DateMixin';
import moment from 'moment';

export default {
    mixins: [getTimeFromSecondsDurationFormatMixinShowZero, exportJsonToCsv, dateHourFormat],
    mounted() {
        this.vehiclesService = new VehiclesService();
        this.showDialogFilters = this.isScreenMobile;
        this.is_col_12 = this.isScreenMobile;
        this.getValuesToPay();
    },

    data() {
        return {
            showFormatHour: true,
            showDialogFilters: false,
            is_col_12: false,
            displayMaximizable: false,
            vehiclesService: null,
            isLoadingSensorData: false,
            selectedOrganizations: [],
            selectedVehicles: [],
            startHour: new Date(1970, 0, 1, 0, 0, 0, 0),
            endHour: new Date(1970, 0, 1, 23, 59, 0, 0),
            tsStartHour: 0,
            tsEndHour: 0,
            showFilterShiftA: false,
            showFilterShiftB: false,
            showFilterShiftC: false,
            originalResponse: null,
            isFilterEndDateNextDay: false,
            workingModeMoney: 0,
            idleModeMoney: 0,
            maneuveringModeMoney: 0,
            travelingModeMoney: 0,
            offModeMoney: 0,
            waitingDataModeMoney: 0,
            inconsistencyModeMoney: 0,
            maintenanceModeMoney: 0,
            consumptionMoney: 0,
            sumConsumption: {},
            SECONDS_IN_HOUR: 3600,
            arrayVehicles: [],
            originalArrayVehicles: [],
            DAY_IN_TIMESTAMP: 86400000,
            payload: {},
            vehicleSelected: 1,
            formatHourOptions: [
                {name: this.$t('AppWorkingModes.Decimal') + ' (0,00)', value: false},
                {name: this.$t('AppWorkingModes.Formatado') + ' (0h0m0s)', value: true}
            ],
            showFilterShift: [],
            showFilterShiftProp: '',
            showFilterShiftOptions: [
                { name: 'Turno A', value: 'A'},
                { name: 'Turno B', value: 'B'},
                { name: 'Turno C', value: 'C'}
            ],
            today: null,
            originalTimeAndConsumptionToFilter: []
        }
    },

    methods: {
        changeVehicleSelected(index){
            this.vehicleSelected = index
        },
        timeAndConsumption(time, consumption){
            let val1 = parseFloat(time.replace('.', '').replace(',', '.'));
            let val2 = parseFloat(consumption.replace('.', '').replace(',', '.'));
            return this.transformToLocaleString(val1 + val2)
        },
        transformToLocaleString(val){
            return Number(val).toLocaleString('pt-BR');
        },
        exportCsv(){
            let arrayFilteredVehicles = this.arrayVehicles.filter(element => element.name != 'Total' && element.name != 'Config');
            let csvData = arrayFilteredVehicles.flatMap(vehicle => vehicle.dataToExportCSV);

            const CSV_FIELDS = ["name", "start", "time", "initial_hour_meter", "final_hour_meter", "consumption", "mode"];
            const CSV_LABELS = [this.$t('AppWorkingModes.Maquina'), this.$t('AppWorkingModes.Inicio'), this.$t('AppWorkingModes.Duração'), this.$t('AppWorkingModes.Horímetro Inicial (h)'), this.$t('AppWorkingModes.Horímetro Final (h)'), this.$t('AppWorkingModes.Combustível (L)'), this.$t('AppWorkingModes.Modo')];

            this.exportJsonToCsv(CSV_FIELDS, CSV_LABELS, csvData, this.$t('AppWorkingModes.Maquina'));
        },
        validateDataPreRequest(dates, selectedOrganizations, selectedVehicles) {
            if (selectedVehicles.length === 0) {
                this.$toast.add({
                    severity: 'error',
                    summary: this.$t('AppWorkingModes.Selecione a organização'),
                    detail: this.$t('AppWorkingModes.Selecione uma organização para continua'),
                    life: 5000
                });
                return;
            }

            let start = Date.parse(dates.start_date) / 1000;
            let end = (Date.parse(dates.end_date) / 1000) + 60;
            if((end - start) / 86400 > 31){
                this.$toast.add({
                    severity: 'error',
                    summary: this.$t('AppWorkingModes.Otimize a Pesquisa'),
                    detail: this.$t('AppWorkingModes.Selecione um período de tempo mais curto'),
                    life: 5000
                });
                return;
            }

            if (selectedVehicles.length > 10) {
                this.$toast.add({
                    severity: 'error',
                    summary: this.$t('AppWorkingModes.Quantidade de veículos'),
                    detail: this.$t('AppWorkingModes.Selecione no máximo 10 veículos'),
                    life: 5000
                });
                return;
            }

            this.displayMaximizable = false;
            this.selectedVehicles = selectedVehicles.sort((a, b) => {
                if (a.display_id < b.display_id) return -1;
                if (a.display_id > b.display_id) return 1;
                return 0;
            });

            this.payload = {
                start_date: moment.tz(dates.start_date, 'America/Sao_Paulo').utc().valueOf(),
                end_date: moment.tz(dates.end_date, 'America/Sao_Paulo').utc().valueOf(),
                ids: selectedVehicles.map(v => v.id)
            };

            this.getVehicles(selectedOrganizations);
        },
        // Faz uma nova requisição dos veículos com o Query Param solicitando o horímetro de cada um
        getVehicles(selectedOrganizations) {
            let promises = [];
            selectedOrganizations.forEach(org => {
                promises.push(this.vehiclesService.getVehiclesByOrgHourMeterV3(org.id));
            });

            Promise.allSettled(promises)
                .then(async (results) => {
                    if (results.map(obj => obj.status).includes("rejected")) {
                        this.$toast.add({
                            severity: 'warn',
                            summary: this.$t('AppWorkingModes.Sem acesso'),
                            detail: this.$t('AppWorkingModes.Você não tem acesso aos veículos de todos as organizações'),
                            life: 5000
                        });
                    }

                    let newArray = results
                        .filter(result => result.status === 'fulfilled')
                        .flatMap(result => result.value);

                    this.selectedVehicles = this.selectedVehicles.map(vehicle => {
                        let updatedVehicle = newArray.find(newVehicle => newVehicle.id === vehicle.id);
                        return updatedVehicle ? updatedVehicle : vehicle;
                    });

                    this.getHistoricalData(this.payload);
                })
                .catch((error) => {
                    this.$toast.add({
                        severity: 'error',
                        summary: this.$t('AppWorkingModes.Operação falhou'),
                        detail: this.$t('AppWorkingModes.Não foi possível buscar veículos'),
                        life: 5000
                    })
                })
        },
        getHistoricalData(payload) {
            this.isLoadingSensorData = true;
            let promisses = []
            payload.ids.forEach(element => {
                promisses.push(this.vehiclesService.getHistoricalDataDashboard(element, payload.start_date, payload.end_date));
            });

            // Faz as requisições em paralelo
            Promise.all(promisses).then((results) => {
                if (results.length != promisses.length) {
                    return
                }

                this.originalResponse = results;
                this.vehicleSelected = this.selectedVehicles.length > 1 ? 1 : 2

                this.setDataByOriginalResponse()
            }).catch(() => {
                this.arrayVehicles = []
                this.$toast.add({
                    severity: 'error',
                    summary: this.$t('AppWorkingModes.Operação falhou'),
                    detail: this.$t('AppWorkingModes.Erro na busca de frotas em paralelo'),
                    life: 5000
                });
            }).finally(() => {
                this.isLoadingSensorData = false;
            })
        },
        setDataByOriginalResponse(){
            this.arrayVehicles = []
            this.sumConsumption = {}
            this.originalTimeAndConsumptionToFilter = []
            this.originalResponse.forEach((result, index) => {
                if(result.length === 0){
                    return
                }

                let temp = {}
                temp.name = this.selectedVehicles[index].display_id
                temp.id = this.selectedVehicles[index].id
                temp.data = result
                this.setData(JSON.parse(JSON.stringify(temp)))
            })

            this.organizeArrayVehicles()

            // Cria uma cópia de this.arrayVehicles para utilizar na filtragem
            this.originalArrayVehicles = JSON.parse(JSON.stringify(this.arrayVehicles.slice(2)));

            // Aplica o filtro por turno se estiver ligado
            if(this.showFilterShiftA || this.showFilterShiftB || this.showFilterShiftC){
                this.filterHistoricalData()
                return
            }
        },
        // Trata os dados e atribui ao this.arrayVehicles
        setData(response){
            let modeToColor = {
                1: '#2CA02C', // green - working
                2: '#D62728', // red - idle
                3: '#FFAA00', // orange - maneuvering
                4: '#1F76B4', // blue - traveling
                5: '#5E3F20', // brown - off
                6: '#FFFF33', // yellow - waiting
                7: '#797979', // gray - lost
                8: '#B507A7', // purple - maintenance
                171: '#FFAEC9', // pale pink - inconsistency
            }

            let timeByMode = {}
            let consumptionByMode = {}
            let dataToExportCSV = []
            let valuesTimeAndConsumption = []
            let series = [{ type: 'line', data: [], markArea: { data: [] }, itemStyle: { color: '#505050' }}]
            response.data.sort((a, b) => a.event_start - b.event_start);
            response.data.forEach((data, i) => {
                let { mode, event_start, event_end, hour_meter_start, hour_meter_end, hour_meter_gap, time } = data;

                // Limpa modos inexistentes
                if(mode === 0){
                    return
                }

                // Transforma dados em timestamp
                let start = event_start * 1000;
                let end = event_end * 1000;
                let duration = mode === 6 ? hour_meter_gap * 3600 : time;

                // Guarda tempo e consumo para usar na filtragem dos turnos
                valuesTimeAndConsumption.push({mode, duration, consumption: data.consumption, event_start, event_end})

                // Calcula o tempo e o consumo dos modos
                timeByMode[mode] = (timeByMode[mode] || 0) + duration;
                consumptionByMode[mode] = (consumptionByMode[mode] || 0) + data.consumption;

                // Extrai os pontos para criar o gráfico de linha
                let hourMeter = hour_meter_start < 1 ? null : hour_meter_start
                series[0].data.push([start, hourMeter]);

                // Verificações para adicionar o hour_meter_end no gráfico
                let nextEvent = response.data[i + 1]
                // Se for o último evento no array
                if(nextEvent === undefined){
                    series[0].data.push(hour_meter_end < 1 ? [end, null] : [end, hour_meter_end])
                }
                // Se o próximo evento começar em um momento diferente ou se o modo do próximo evento for zero
                if(nextEvent != undefined && ((nextEvent.event_start !== event_end) || (nextEvent.mode === 0))){
                    series[0].data.push(hour_meter_end < 1 ? [end, null] : [end, hour_meter_end], [end, null]);
                }
                // Se o próximo evento não tem valor inicial de horímetro, mas o evento atual tem
                if(nextEvent != undefined && nextEvent.hour_meter_start < 1 && hour_meter_end >= 1){
                    series[0].data.push([end, hour_meter_end])
                }

                // Caso o ultimo evento for o mesmo que o atual, eles se unem
                if(series[0].markArea.data.length > 0 && series[0].markArea.data[series[0].markArea.data.length - 1][0].itemStyle.color === modeToColor[mode] &&
                        series[0].markArea.data[series[0].markArea.data.length - 1][1].xAxis === start){
                    series[0].markArea.data[series[0].markArea.data.length - 1][1].xAxis = end
                } else{
                    series[0].markArea.data.push([
                        { xAxis: start, itemStyle: { color: modeToColor[mode], opacity: 0.7 } },
                        { xAxis: end }
                    ]);
                }

                if(hour_meter_start < 1 || hour_meter_end < 1){
                    return
                }

                // Cria o array para exportar CSV
                let modeToCsvMap = {
                    1: this.$t('AppWorkingModes.Produzindo'),
                    2: this.$t('AppWorkingModes.Ocioso'),
                    3: this.$t('AppWorkingModes.Manobrando'),
                    4: this.$t('AppWorkingModes.Deslocando'),
                    5: this.$t('AppWorkingModes.Desligado'),
                    6: this.$t('AppWorkingModes.Aguardando Dados'),
                    7: this.$t('AppWorkingModes.Não Monitorado'),
                    8: this.$t('AppWorkingModes.Manutenção'),
                    171: this.$t('AppWorkingModes.Incoerência')
                };

                let modeToCsv = modeToCsvMap[mode] || "";

                dataToExportCSV.push({
                    name: response.name,
                    start: this.dateHourFormat(start / 1000),
                    time: (duration / 3600).toFixed(4).replace(".", ","),
                    initial_hour_meter: hour_meter_start.toLocaleString('pt-BR'),
                    final_hour_meter: hour_meter_end.toLocaleString('pt-BR'),
                    consumption: data.consumption.toLocaleString('pt-BR'),
                    mode: modeToCsv
                })
            });

            // Verifica se existem dados
            if( !timeByMode[1] && !timeByMode[2] && !timeByMode[3] && !timeByMode[4] &&
                !timeByMode[5] && !timeByMode[6] && !timeByMode[8] && !timeByMode[171]){
                return;
            }

            let endDate = new Date(this.payload.end_date);
            let today = new Date();
            this.today = today;

            // Pega o último ponto válido do gráfico
            let lastGraphicPoint = null;
            for (let i = series[0].data.length - 1; i >= 0; i--) {
                if (series[0].data[i][1] !== null && series[0].data[i][1] !== undefined) {
                    lastGraphicPoint = series[0].data[i];
                    break;
                }
            }

            // Se o dia filtrado for hoje é adicionado Aguardando dados ou Desligado ao final
            if (endDate.toDateString() === today.toDateString()) {
                let vehicle = this.selectedVehicles.find(element => element.id === response.id);

                if(vehicle && vehicle.hour_meter && lastGraphicPoint != null){
                    if(vehicle.hour_meter < lastGraphicPoint[1] || (vehicle.hour_meter_ts && series[0].data[series[0].data.length - 1][0] >= vehicle.hour_meter_ts * 1000)){
                        series[0].data.push([today.getTime(), null])
                        series[0].markArea.data.push([
                            { xAxis: series[0].data[series[0].data.length - 2][0], itemStyle: { color: '#5E3F20', opacity: 0.7 } },
                            { xAxis: today.getTime() }
                        ]);
                    } else{
                        let duration;
                        if(vehicle.hour_meter_ts){
                            duration = vehicle.hour_meter_ts - (series[0].data[series[0].data.length - 1][0] / 1000) > 0 ? vehicle.hour_meter_ts - (series[0].data[series[0].data.length - 1][0] / 1000) : 0
                        } else{
                            duration = (vehicle.hour_meter - lastGraphicPoint[1]) * 3600 > 0 ? (vehicle.hour_meter - lastGraphicPoint[1]) * 3600 : 0
                        }

                        let item = vehicle.hour_meter > lastGraphicPoint[1] ? { color: '#FFFF33', mode: 6, } : { color: '#5E3F20', mode: 5}

                        valuesTimeAndConsumption.push({mode: item.mode, duration, consumption: 0, event_start: series[0].data[series[0].data.length - 2][0] / 1000, event_end: today.getTime() / 1000 })
                        timeByMode[item.mode] = (timeByMode[item.mode] || 0) + duration
                        series[0].data.push([today.getTime(), null])
                        series[0].markArea.data.push([
                            { xAxis: series[0].data[series[0].data.length - 2][0], itemStyle: { color: item.color, opacity: 0.7 } },
                            { xAxis: today.getTime() }
                        ]);
                    }
                }
            }
            // Caso o dia filtrado não seja hoje mas existe um espaço vazio ao final do gráfico
            else if (this.payload.end_date + 1 > series[0].data[series[0].data.length - 1][0] ) {
                let vehicle = this.selectedVehicles.find(element => element.id === response.id);

                if( vehicle &&
                    vehicle.hour_meter_ts &&
                    vehicle.hour_meter &&
                    series[0].data[series[0].data.length - 1][0] < vehicle.hour_meter_ts * 1000 &&
                    lastGraphicPoint != null){

                    let finalPoint = vehicle.hour_meter_ts * 1000 > this.payload.end_date + 1 ? this.payload.end_date + 1 : vehicle.hour_meter_ts * 1000
                    let color = vehicle.hour_meter > lastGraphicPoint[1] ? '#FFFF33' : '#5E3F20'

                    series[0].markArea.data.push([
                        { xAxis: series[0].data[series[0].data.length - 1][0], itemStyle: { color: color, opacity: 0.7 } },
                        { xAxis: finalPoint }
                    ]);
                }
            }

            // Guarda tempo e consumo por veículo para usar na filtragem dos turnos
            this.originalTimeAndConsumptionToFilter.push({name: response.name, value: valuesTimeAndConsumption})

            // Adiciona pontos nas meia noites
            if(!this.showFilterShiftA && !this.showFilterShiftB && !this.showFilterShiftC){
                let midnightPoints = this.getMidnightPoints()
                midnightPoints.forEach(element => {
                    series[0].data.push(element)
                });
            }

            // Atribui a soma dos modos as suas variaveis
            let workingConsumption = consumptionByMode[1] || 0;
            let idleConsumption = consumptionByMode[2] || 0;
            let maneuveringConsumption = consumptionByMode[3] || 0;
            let travelingConsumption = consumptionByMode[4] || 0;
            let maintenanceConsumption = consumptionByMode[8] || 0;
            let inconsistencyConsumption = consumptionByMode[171] || 0;
            let dataConsumption = workingConsumption + idleConsumption + maneuveringConsumption + travelingConsumption + maintenanceConsumption + inconsistencyConsumption;

            // Atribui a soma dos modos as suas variaveis
            let workingSeconds = timeByMode[1] || 0;
            let idleSeconds = timeByMode[2] || 0;
            let maneuveringSeconds = timeByMode[3] || 0;
            let travelingSeconds = timeByMode[4] || 0;
            let offSeconds = timeByMode[5] || 0;
            let waitingSeconds = timeByMode[6] || 0;
            let lostSecods = timeByMode[7] || 0;
            let maintenanceSeconds = timeByMode[8] || 0;
            let inconsistencySeconds = timeByMode[171] || 0;
            let dataSeconds = workingSeconds + idleSeconds + maneuveringSeconds + travelingSeconds + offSeconds + maintenanceSeconds + inconsistencySeconds;

            // Atribui os dados para os gráficos de pizza
            let dataToPieTime = this.setDataToPieTime(workingSeconds, idleSeconds, maneuveringSeconds, travelingSeconds, offSeconds, waitingSeconds, lostSecods, maintenanceSeconds, inconsistencySeconds)
            let dataToPieConsumption = this.setDataToPieConsumption(workingConsumption, idleConsumption, maneuveringConsumption, travelingConsumption, maintenanceConsumption, inconsistencyConsumption)

            // Encontrar o primeiro e ultimo horímetro item não nulo
            let firstHourMeter;
            for (let i = 0; i < series[0].data.length; i++) {
                if (series[0].data[i][1] !== null && series[0].data[i][1] !== undefined) {
                    firstHourMeter = series[0].data[i][1].toLocaleString('pt-BR');
                    break;
                }
            }

            let lastHourMeter;
            for (let i = series[0].data.length - 1; i >= 0; i--) {
                if (series[0].data[i][1] !== null && series[0].data[i][1] !== undefined) {
                    lastHourMeter = series[0].data[i][1].toLocaleString('pt-BR');
                    break;
                }
            }

            // Pega o último contato da máquina, calcula o tempo ligado e organiza o subtitulo
            let subTitle = '';
            let timeOn = 0;
            let lastContact = '';
            let selectedVehicle = this.selectedVehicles.find(element => element.display_id === response.name);
            if (selectedVehicle) {
                let { type, manufacturer, model, identification_number, last_contact, last_modified_date, hour_meter_ts } = selectedVehicle;
                subTitle = `${type} - ${manufacturer} ${model} - ${identification_number}`;
                if (lastHourMeter !== undefined && firstHourMeter !== undefined) {
                    let lastHour = parseFloat(lastHourMeter.replace('.', '').replace(',', '.'));
                    let firstHour = parseFloat(firstHourMeter.replace('.', '').replace(',', '.'));
                    timeOn = (lastHour - firstHour).toLocaleString('pt-BR');
                }
                lastContact = last_contact;
            }

            // Atribui os dados que os componentes receberão
            this.arrayVehicles.push({
                name: response.name,
                subTitle,
                workingConsumption,
                idleConsumption,
                maneuveringConsumption,
                travelingConsumption,
                inconsistencyConsumption,
                inconsistencySeconds,
                dataConsumption,
                workingSeconds,
                idleSeconds,
                maneuveringSeconds,
                travelingSeconds,
                offSeconds,
                lostSecods,
                waitingSeconds,
                dataSeconds,
                timeOn,
                maintenanceConsumption,
                maintenanceSeconds,
                firstHourMeter: firstHourMeter || 0,
                lastHourMeter: lastHourMeter || 0,
                dataToLine: series,
                dataToPieTime,
                dataToPieConsumption,
                lastContact,
                dataToExportCSV
            })

            // Adiciona a aba config e total no inicio de this.arrayVehicles; Faz a soma total para popular a aba "Total"
            this.addConfigAndTotal(workingSeconds, idleSeconds, maneuveringSeconds, travelingSeconds, offSeconds, waitingSeconds, maintenanceSeconds, inconsistencySeconds, dataSeconds,
                workingConsumption, idleConsumption, maneuveringConsumption, travelingConsumption, maintenanceConsumption, dataConsumption, lostSecods, inconsistencyConsumption)
        },
        setDataToPieTime(workingSeconds, idleSeconds, maneuveringSeconds, travelingSeconds, offSeconds, waitingSeconds, lostSeconds, maintenanceSeconds, inconsistencySeconds) {
            let data = [
                { name: this.$t('AppWorkingModes.Não Monitorado'), value: lostSeconds, itemStyle: {color: '#797979'} },
                { name: this.$t('AppWorkingModes.Aguardando Dados'), value: waitingSeconds, itemStyle: {color: '#FFFF33'} },
                { name: this.$t('AppWorkingModes.Desligado'), value: offSeconds, itemStyle: {color: '#5E3F20'} },
                { name: this.$t('AppWorkingModes.Ocioso'), value: idleSeconds, itemStyle: {color: '#D62728'} },
                { name: this.$t('AppWorkingModes.Manutenção'), value: maintenanceSeconds, itemStyle: {color: '#B507A7'} },
                { name: this.$t('AppWorkingModes.Deslocando'), value: travelingSeconds, itemStyle: {color: '#1F76B4'} },
                { name: this.$t('AppWorkingModes.Manobrando'), value: maneuveringSeconds, itemStyle: {color: '#FFAA00'} },
                { name: this.$t('AppWorkingModes.Produzindo'), value: workingSeconds, itemStyle: {color: '#2CA02C'} },
                { name: this.$t('AppWorkingModes.Incoerência'), value: inconsistencySeconds, itemStyle: {color: '#FFAEC9'} }
            ]
            data = data.filter(element => element.value != 0)
            return data;
        },
        setDataToPieConsumption(workingConsumption, idleConsumption, maneuveringConsumption, travelingConsumption, maintenanceConsumption, inconsistencyConsumption) {
            let data = [
                { name: this.$t('AppWorkingModes.Ocioso'), value: idleConsumption, itemStyle: {color: '#D62728'} },
                { name: this.$t('AppWorkingModes.Deslocando'), value: travelingConsumption, itemStyle: {color: '#1F76B4'} },
                { name: this.$t('AppWorkingModes.Manobrando'), value: maneuveringConsumption, itemStyle: {color: '#FFAA00'} },
                { name: this.$t('AppWorkingModes.Manutenção'), value: maintenanceConsumption, itemStyle: {color: '#B507A7'} },
                { name: this.$t('AppWorkingModes.Produzindo'), value: workingConsumption, itemStyle: {color: '#2CA02C'} },
                { name: this.$t('AppWorkingModes.Incoerência'), value: inconsistencyConsumption, itemStyle: {color: '#FFAEC9'} }
            ]
            data = data.filter(element => element.value != 0)
            return data;
        },
        getFilteredTime(){
            if(!this.showFilterShiftA && !this.showFilterShiftB && !this.showFilterShiftC){
                return this.payload.end_date - this.payload.start_date;
            }

            let startDateFilter = new Date(this.startHour).getTime();
            let endDateFilter = new Date(this.endHour).getTime();

            if(startDateFilter >= endDateFilter){
                endDateFilter = new Date(this.endHour).getTime() + this.DAY_IN_TIMESTAMP;
            }

            return (endDateFilter - startDateFilter) * (this.payload.end_date - this.payload.start_date) / this.DAY_IN_TIMESTAMP;
        },
        getMidnightPoints(){
            let currentDay = new Date(this.payload.start_date);
            let endDate = new Date(this.payload.end_date);
            let today = new Date();
            today.setHours(0, 0, 0, 0);

            let midnightPoints = []
            while (currentDay <= endDate) {
                let midnightTimestamp = new Date(currentDay);
                midnightTimestamp.setHours(0, 0, 0, 0);

                midnightPoints.push([midnightTimestamp.getTime(), null]);
                currentDay.setDate(currentDay.getDate() + 1);
            }

            if (endDate.toDateString() != today.toDateString()) {
                endDate.setHours(23, 59, 59, 999);
                midnightPoints.push([endDate.getTime() + 1, null]);
            }
            return midnightPoints
        },
        addConfigAndTotal(workingSeconds, idleSeconds, maneuveringSeconds, travelingSeconds, offSeconds, waitingSeconds, maintenanceSeconds, inconsistencySeconds, dataSeconds,
            workingConsumption, idleConsumption, maneuveringConsumption, travelingConsumption, maintenanceConsumption, dataConsumption, lostSecods, inconsistencyConsumption) {
            if (this.arrayVehicles[1] && this.arrayVehicles[1].name === "Total") {
                let vehicle = this.arrayVehicles[1];

                vehicle.workingConsumption += workingConsumption;
                vehicle.idleConsumption += idleConsumption;
                vehicle.maneuveringConsumption += maneuveringConsumption;
                vehicle.travelingConsumption += travelingConsumption;
                vehicle.dataConsumption += dataConsumption;
                vehicle.maintenanceConsumption += maintenanceConsumption;
                vehicle.inconsistencyConsumption += inconsistencyConsumption;

                vehicle.workingSeconds += workingSeconds;
                vehicle.idleSeconds += idleSeconds;
                vehicle.maneuveringSeconds += maneuveringSeconds;
                vehicle.travelingSeconds += travelingSeconds;
                vehicle.offSeconds += offSeconds;
                vehicle.lostSecods += lostSecods;
                vehicle.waitingSeconds += waitingSeconds;
                vehicle.dataSeconds += dataSeconds;
                vehicle.maintenanceSeconds += maintenanceSeconds;
                vehicle.inconsistencySeconds += inconsistencySeconds;

                vehicle.dataToPieTime = this.setDataToPieTime(
                    vehicle.workingSeconds,
                    vehicle.idleSeconds,
                    vehicle.maneuveringSeconds,
                    vehicle.travelingSeconds,
                    vehicle.offSeconds,
                    vehicle.waitingSeconds,
                    vehicle.lostSecods,
                    vehicle.maintenanceSeconds,
                    vehicle.inconsistencySeconds
                );

                vehicle.dataToPieConsumption = this.setDataToPieConsumption(
                    vehicle.workingConsumption,
                    vehicle.idleConsumption,
                    vehicle.maneuveringConsumption,
                    vehicle.travelingConsumption,
                    vehicle.maintenanceConsumption,
                    vehicle.inconsistencyConsumption
                );

                let filteredTime = this.getFilteredTime();
                let percent = ((vehicle.dataSeconds * 1000) / filteredTime * 100 / this.selectedVehicles.length).toFixed(2);
                vehicle.totalPercent = Number(percent).toLocaleString('pt-BR');
            } else {
                let filteredTime = this.getFilteredTime();
                let percent = ((dataSeconds * 1000) / filteredTime * 100 / this.selectedVehicles.length).toFixed(2)
                this.arrayVehicles.unshift({
                    name: "Total",
                    workingConsumption,
                    idleConsumption,
                    maneuveringConsumption,
                    travelingConsumption,
                    dataConsumption,
                    workingSeconds,
                    idleSeconds,
                    maneuveringSeconds,
                    travelingSeconds,
                    inconsistencySeconds,
                    inconsistencyConsumption,
                    offSeconds,
                    lostSecods,
                    waitingSeconds,
                    dataSeconds,
                    maintenanceConsumption,
                    maintenanceSeconds,
                    firstHourMeter: 0,
                    lastHourMeter: 0,
                    dataToLine: [],

                    dataToPieTime: this.setDataToPieTime(
                        workingSeconds,
                        idleSeconds,
                        maneuveringSeconds,
                        travelingSeconds,
                        offSeconds,
                        waitingSeconds,
                        lostSecods,
                        maintenanceSeconds,
                        inconsistencySeconds
                    ),

                    dataToPieConsumption: this.setDataToPieConsumption(
                        workingConsumption,
                        idleConsumption,
                        maneuveringConsumption,
                        travelingConsumption,
                        maintenanceConsumption,
                        inconsistencyConsumption
                    ),

                    dataToExportCSV: [],
                    totalPercent: Number(percent).toLocaleString('pt-BR')
                })
            }

            if (this.arrayVehicles[0].name != "Config") {
                this.arrayVehicles.unshift({
                    name: "Config",
                    workingSeconds: 0,
                    idleSeconds: 0,
                    maneuveringSeconds: 0,
                    travelingSeconds: 0,
                    offSeconds: 0,
                    lostSecods: 0,
                    waitingSeconds: 0,
                    maintenanceConsumption: 0,
                    maintenanceSeconds: 0,
                    inconsistencySeconds: 0,
                    dataSeconds: 0,
                    firstHourMeter: 0,
                    lastHourMeter: 0,
                    dataToLine: [{ type: 'line', data: [], markArea: { data: [] } }],
                    dataToPie: [],
                    dataToExportCSV: {}
                })
            }
        },
        // Adiciona gráficos vazios referentes aos veiculos sem dados
        organizeArrayVehicles(){
            this.originalResponse.forEach((result, index) => {
                let isData = result.some(element => element.mode != 7);
                if(result.length > 0 && isData){
                    return
                }

                let temp = {}
                temp.name = this.selectedVehicles[index].display_id
                temp.id = this.selectedVehicles[index].id
                let lastHourMeterTest = this.selectedVehicles[index].hour_meter
                let newData = []
                let hourlyTimestamps = this.generateHourlyTimestamps(this.payload.start_date, this.payload.end_date)
                hourlyTimestamps.forEach((element, index) => {
                    if(!hourlyTimestamps[index + 1]){
                        return
                    }
                    newData.push({
                        consumption: 0,
                        event_start: element / 1000,
                        event_end: hourlyTimestamps[index + 1] / 1000,
                        mode: 5,
                        time: (hourlyTimestamps[index + 1] / 1000) - (element / 1000),
                        hour_meter_start: lastHourMeterTest,
                        hour_meter_end: lastHourMeterTest,
                        hour_meter_gap: 0
                    })
                });
                temp.data = newData
                this.setData(JSON.parse(JSON.stringify(temp)))
            })

            if(this.arrayVehicles[1] && this.arrayVehicles[1].name != 'Total'){
                this.arrayVehicles.unshift({
                    name: "Total",
                    workingSeconds: 0,
                    idleSeconds: 0,
                    maneuveringSeconds: 0,
                    travelingSeconds: 0,
                    offSeconds: 0,
                    lostSecods: 0,
                    waitingSeconds: 0,
                    maintenanceConsumption: 0,
                    maintenanceSeconds: 0,
                    inconsistencySeconds: 0,
                    dataSeconds: 0,
                    firstHourMeter: 0,
                    lastHourMeter: 0,
                    dataToLine: [{ type: 'line', data: [], markArea: { data: [] } }],
                    dataToPie: [],
                    dataToExportCSV: {}
                })
            }

            if(this.arrayVehicles[0].name != 'Config'){
                this.arrayVehicles.unshift({
                    name: "Config",
                    workingSeconds: 0,
                    idleSeconds: 0,
                    maneuveringSeconds: 0,
                    travelingSeconds: 0,
                    offSeconds: 0,
                    lostSecods: 0,
                    waitingSeconds: 0,
                    maintenanceConsumption: 0,
                    maintenanceSeconds: 0,
                    inconsistencySeconds: 0,
                    dataSeconds: 0,
                    firstHourMeter: 0,
                    lastHourMeter: 0,
                    dataToLine: [{ type: 'line', data: [], markArea: { data: [] } }],
                    dataToPie: [],
                    dataToExportCSV: {}
                })
            }

            let arrayVehiclesCopy = this.arrayVehicles.slice(2)

            let filteredElements = arrayVehiclesCopy.filter((element, index) => {
                return this.bgColorGetItemByArrayVehicles(index + 2)
            })

            filteredElements.sort((a, b) => {
                if (a.name < b.name) return -1
                if (a.name > b.name) return 1
                return 0
            })

            this.arrayVehicles = this.arrayVehicles.filter((element, index) => {
                return index < 2 || !this.bgColorGetItemByArrayVehicles(index)
            })

            this.arrayVehicles.push(...filteredElements)
        },
        generateHourlyTimestamps(startTimestamp, endTimestamp) {
            let startDate = new Date(startTimestamp);
            let endDate = new Date(endTimestamp);
            let now = new Date();
            endDate.setMinutes(0);
            endDate.setSeconds(0);
            endDate.setMilliseconds(0);
            let lastDate = endDate.toDateString() === now.toDateString() ? now : endDate
            let timestamps = [];
            while (startDate <= lastDate) {
                timestamps.push(startDate.getTime());
                startDate.setHours(startDate.getHours() + 1);
            }
            timestamps.push(endDate.toDateString() === now.toDateString() ? now.getTime() : endDate.getTime() + 3600000);
            return timestamps;
        },
        // Filtra o this.originalArrayVehicles por turno
        filterHistoricalData(){
            // Cria texto com o turno e horário filtrado para adicionar ao gráfico
            this.showFilterShiftProp = this.showFilterShiftA ? 'TURNO A' : this.showFilterShiftB ? 'TURNO B' : 'TURNO C'
            let formattedEndHour = this.tsEndHour < 10 ? `0${this.tsEndHour}` : this.tsEndHour;
            let formattedStartHour = this.tsStartHour < 10 ? `0${this.tsStartHour}` : this.tsStartHour;
            this.showFilterShiftProp = `${this.showFilterShiftProp}: ${formattedStartHour}:00 - ${formattedEndHour}:00`;

            // Verifica se filtro atravessa a meia noite
            this.isFilterEndDateNextDay = this.tsStartHour > this.tsEndHour || this.tsStartHour === this.tsEndHour

            // Se horários forem iguais não há necessidade de filtragem
            if(this.tsStartHour === this.tsEndHour){
                return
            }

            // Cria um array com os intervalos de timestamps a serem incluídos no filtro
            let start_date = this.payload.start_date;
            let verifyEndDate = new Date(this.payload.end_date + 1);
            let end_date = verifyEndDate.toDateString() === this.today.toDateString() ? this.today.getTime() : this.payload.end_date + 1;
            let pointsArray = this.getTsInFilter(start_date, end_date)

            // Limpa a aba Total
            this.cleanTotal()

            this.originalArrayVehicles.forEach((element, index) => {

                // Filtra os pontos do gráfico
                let dataToLineData = []
                element.dataToLine[0].data.forEach(item => {
                    let isAdd = false;
                    pointsArray.forEach(point => {
                        if(item[0] >= point.first && item[0] <= point.last){
                            isAdd = true
                            dataToLineData.push(item)
                        }
                    });

                    if(!isAdd){
                        dataToLineData.push([item[0], null])
                    }
                });
                dataToLineData.unshift([start_date, null])
                dataToLineData.push([end_date, null])

                // Filtra as cores que aparecem atrás dos pontos no gráfico
                let dataToLineMarkArea = []
                element.dataToLine[0].markArea.data.forEach(element => {
                    let eventStart = element[0].xAxis;
                    let eventEnd = element[1].xAxis;

                    pointsArray.forEach(point => {
                        let copyElement = JSON.parse(JSON.stringify(element));

                        if(eventStart >= point.first && eventEnd <= point.last){
                            dataToLineMarkArea.push(element)
                            return
                        }

                        if((eventStart >= point.first && eventStart < point.last) && eventEnd > point.last){
                            copyElement[1].xAxis = point.last
                            dataToLineMarkArea.push(copyElement)
                            return
                        }

                        if((eventEnd > point.first && eventEnd <= point.last) && eventStart < point.first){
                            copyElement[0].xAxis = point.first
                            dataToLineMarkArea.push(copyElement)
                            return
                        }

                        if(eventStart < point.first && eventEnd > point.last){
                            copyElement[0].xAxis = point.first
                            copyElement[1].xAxis = point.last
                            dataToLineMarkArea.push(copyElement)
                            return
                        }
                    });
                });

                // Pega o veículo que está sendo editado
                let vehicle = this.arrayVehicles[index + 2];

                // Se não existir dados no período filtrado apresenta "Sem Dados" na tela
                if(dataToLineMarkArea.length === 0){
                    Object.assign(vehicle, {
                        name: vehicle.name,
                        subTitle: vehicle.subTitle,
                        workingConsumption: 0,
                        idleConsumption: 0,
                        maneuveringConsumption: 0,
                        travelingConsumption: 0,
                        dataConsumption: 0,
                        inconsistencyConsumption: 0,
                        inconsistencySeconds: 0,
                        workingSeconds: 0,
                        idleSeconds: 0,
                        maneuveringSeconds: 0,
                        travelingSeconds: 0,
                        offSeconds: 0,
                        lostSecods: 0,
                        waitingSeconds: 0,
                        dataSeconds: 0,
                        timeOn: 0,
                        maintenanceConsumption: 0,
                        maintenanceSeconds: 0,
                        firstHourMeter:  0,
                        lastHourMeter: 0,
                        dataToLine: [{ type: 'line', data: [], markArea: { data: [] } }],
                        dataToPieTime: [],
                        dataToPieConsumption: [],
                        lastContact: vehicle.lastContact,
                        dataToExportCSV: {}
                    });
                    return
                }

                // Filtra o tempo e consumo com base no this.originalTimeAndConsumptionToFilter
                let timeByMode = {}
                let consumptionByMode = {}
                let item = this.originalTimeAndConsumptionToFilter.find(element => element.name === vehicle.name).value
                item.forEach(element => {
                    pointsArray.forEach(point => {
                        if(element.event_start >= point.first / 1000 && element.event_end <= point.last / 1000){
                            timeByMode[element.mode] = (timeByMode[element.mode] || 0) + element.duration;
                            consumptionByMode[element.mode] = (consumptionByMode[element.mode] || 0) + element.consumption;
                        }
                    });
                });

                // Atribui os dados para os gráficos de pizza
                let workingConsumption = consumptionByMode[1] || 0;
                let idleConsumption = consumptionByMode[2] || 0;
                let maneuveringConsumption = consumptionByMode[3] || 0;
                let travelingConsumption = consumptionByMode[4] || 0;
                let maintenanceConsumption = consumptionByMode[8] || 0;
                let inconsistencyConsumption = consumptionByMode[171] || 0;
                let dataConsumption = workingConsumption + idleConsumption + maneuveringConsumption + travelingConsumption + maintenanceConsumption + inconsistencyConsumption;

                // Atribui os dados para os gráficos de pizza
                let workingSeconds = timeByMode[1] || 0;
                let idleSeconds = timeByMode[2] || 0;
                let maneuveringSeconds = timeByMode[3] || 0;
                let travelingSeconds = timeByMode[4] || 0;
                let offSeconds = timeByMode[5] || 0;
                let waitingSeconds = timeByMode[6] || 0;
                let lostSecods = timeByMode[7] || 0;
                let maintenanceSeconds = timeByMode[8] || 0;
                let inconsistencySeconds = timeByMode[171] || 0;
                let dataSeconds = workingSeconds + idleSeconds + maneuveringSeconds + travelingSeconds + offSeconds + maintenanceSeconds + inconsistencySeconds;

                // Atribui os dados para os gráficos de pizza
                let dataToPieTime = this.setDataToPieTime(workingSeconds, idleSeconds, maneuveringSeconds, travelingSeconds, offSeconds, waitingSeconds, lostSecods, maintenanceSeconds, inconsistencySeconds)
                let dataToPieConsumption = this.setDataToPieConsumption(workingConsumption, idleConsumption, maneuveringConsumption, travelingConsumption, maintenanceConsumption, inconsistencyConsumption)

                // Pega o horímetro inicial e final válidos; Calcula o tempo de veículo ligado
                let firstHourMeter;
                for (let i = 0; i < dataToLineData.length; i++) {
                    if (dataToLineData[i][1] !== null && dataToLineData[i][1] !== undefined) {
                        firstHourMeter = dataToLineData[i][1].toLocaleString('pt-BR');
                        break;
                    }
                }

                let lastHourMeter;
                for (let i = dataToLineData.length - 1; i >= 0; i--) {
                    if (dataToLineData[i][1] !== null && dataToLineData[i][1] !== undefined) {
                        lastHourMeter = dataToLineData[i][1].toLocaleString('pt-BR');
                        break;
                    }
                }

                let timeOn = 0;
                dataToLineData.forEach((element, i) => {
                    if (element[1] != null && dataToLineData[i + 1]?.[1] != null) {
                        let difference = Math.max(dataToLineData[i + 1][1] - element[1], 0);
                        timeOn += difference
                    }
                });

                // Atualiza os valores filtrados para this.arrayVehicles
                Object.assign(vehicle, {
                    workingConsumption,
                    idleConsumption,
                    maneuveringConsumption,
                    travelingConsumption,
                    maintenanceConsumption,
                    dataConsumption,
                    workingSeconds,
                    idleSeconds,
                    maneuveringSeconds,
                    travelingSeconds,
                    offSeconds,
                    waitingSeconds,
                    lostSecods,
                    inconsistencySeconds,
                    inconsistencyConsumption,
                    maintenanceSeconds,
                    dataSeconds,
                    dataToPieTime,
                    dataToPieConsumption,
                    timeOn: timeOn.toLocaleString('pt-BR'),
                    firstHourMeter: firstHourMeter || 0,
                    lastHourMeter: lastHourMeter || 0,
                    dataToLine: [{ type: 'line', data: dataToLineData, markArea: { data: dataToLineMarkArea }, itemStyle: { color: '#505050' }}]
                });

                // Adiciona a aba config e total no inicio de this.arrayVehicles; Faz a soma total para popular a aba "Total"
                this.addConfigAndTotal(workingSeconds, idleSeconds, maneuveringSeconds, travelingSeconds, offSeconds, waitingSeconds, maintenanceSeconds, inconsistencySeconds, dataSeconds,
                    workingConsumption, idleConsumption, maneuveringConsumption, travelingConsumption, maintenanceConsumption, dataConsumption, lostSecods, inconsistencyConsumption)
            });
        },
        getTsInFilter(start_date, end_date){
            let startDate = new Date(start_date);
            let endDate = new Date(end_date);

            let pointsArray = [];

            while (startDate < endDate) {
                let firstTs = new Date(startDate.getTime());
                let lastTs = new Date(startDate.getTime());

                firstTs.setHours(this.tsStartHour, 0, 0, 0);

                if (this.tsEndHour < this.tsStartHour) {
                    lastTs.setDate(lastTs.getDate() + 1);
                }

                lastTs.setHours(this.tsEndHour, 0, 0, 0);

                pointsArray.push({
                    first: firstTs.getTime(),
                    last: lastTs.getTime() > end_date ? end_date : lastTs.getTime()
                });

                startDate = new Date(startDate.getTime() + 86400000);
            }

            if (this.tsEndHour < this.tsStartHour && this.tsEndHour != 0) {
                let lastDate = new Date(start_date);
                lastDate.setHours(this.tsEndHour, 0, 0, 0);
                pointsArray.unshift({
                    first: start_date,
                    last: lastDate.getTime()
                });
            }

            if (this.tsEndHour < this.tsStartHour) {
                pointsArray[pointsArray.length - 1].last += 59999
            }

            return pointsArray;
        },
        cleanTotal(){
            this.arrayVehicles[1] = {
                name: "Total",
                workingConsumption: 0,
                idleConsumption: 0,
                maneuveringConsumption: 0,
                travelingConsumption: 0,
                dataConsumption: 0,
                workingSeconds: 0,
                idleSeconds: 0,
                inconsistencySeconds: 0,
                inconsistencyConsumption: 0,
                maneuveringSeconds: 0,
                travelingSeconds: 0,
                offSeconds: 0,
                lostSecods: 0,
                waitingSeconds: 0,
                dataSeconds: 0,
                maintenanceConsumption: 0,
                maintenanceSeconds: 0,
                firstHourMeter: 0,
                lastHourMeter: 0,
                dataToLine: [],
                dataToPieTime: null,
                dataToPieConsumption: null,
                dataToExportCSV: [],
                totalPercent: 0
            }
        },
        // Recebe valores por modo de trabalho do LocalStorage
        getValuesToPay(){
            let temp = JSON.parse(localStorage.getItem(process.env.VUE_APP_LOCAL_STORAGE_WORKING_MODES_VALUES));

            if (temp === null || temp === undefined || typeof temp.shift_a_start === 'undefined' ||
            typeof temp.shift_a_end === 'undefined' || typeof temp.shift_b_start === 'undefined' ||
            typeof temp.shift_b_end === 'undefined' || typeof temp.shift_c_start === 'undefined' ||
            typeof temp.shift_c_end === 'undefined' || !Array.isArray(temp.price_of_modes) ||
            temp.price_of_modes.length !== 9){
                let localStorageDefault = {
                    shift_a_start: 0,
                    shift_a_end: 0,
                    shift_b_start: 0,
                    shift_b_end: 0,
                    shift_c_start: 0,
                    shift_c_end: 0,
                    price_of_modes: [0, 0, 0, 0, 0, 0, 0, 0, 0]
                }
                localStorage.setItem(process.env.VUE_APP_LOCAL_STORAGE_WORKING_MODES_VALUES, JSON.stringify(localStorageDefault));
                return
            }

            this.workingModeMoney = temp.price_of_modes[0] === undefined || temp.price_of_modes[0] === null ? 0 : temp.price_of_modes[0]
            this.idleModeMoney = temp.price_of_modes[1] === undefined || temp.price_of_modes[1] === null ? 0 : temp.price_of_modes[1]
            this.maneuveringModeMoney = temp.price_of_modes[2] === undefined || temp.price_of_modes[2] === null ? 0 : temp.price_of_modes[2]
            this.travelingModeMoney = temp.price_of_modes[3] === undefined || temp.price_of_modes[3] === null ? 0 : temp.price_of_modes[3]
            this.offModeMoney =  temp.price_of_modes[4] === undefined || temp.price_of_modes[4] === null ? 0 : temp.price_of_modes[4]
            this.waitingDataModeMoney = temp.price_of_modes[5] === undefined || temp.price_of_modes[5] === null ? 0 : temp.price_of_modes[5]
            this.maintenanceModeMoney = temp.price_of_modes[6] === undefined || temp.price_of_modes[6] === null ? 0 : temp.price_of_modes[6]
            this.consumptionMoney = temp.price_of_modes[7] === undefined || temp.price_of_modes[7] === null ? 0 : temp.price_of_modes[7]
            this.inconsistencyModeMoney = temp.price_of_modes[8] === undefined || temp.price_of_modes[8] === null ? 0 : temp.price_of_modes[8]
        },
        // Seta valores por modo de trabalho no LocalStorage
        setValuesToPay(){
            let temp = JSON.parse(localStorage.getItem(process.env.VUE_APP_LOCAL_STORAGE_WORKING_MODES_VALUES));

            temp.price_of_modes[0] = this.workingModeMoney
            temp.price_of_modes[1] = this.idleModeMoney
            temp.price_of_modes[2] = this.maneuveringModeMoney
            temp.price_of_modes[3] = this.travelingModeMoney
            temp.price_of_modes[4] = this.offModeMoney
            temp.price_of_modes[5] = this.waitingDataModeMoney
            temp.price_of_modes[6] = this.maintenanceModeMoney
            temp.price_of_modes[7] = this.consumptionMoney
            temp.price_of_modes[8] = this.inconsistencyModeMoney
            localStorage.setItem(process.env.VUE_APP_LOCAL_STORAGE_WORKING_MODES_VALUES, JSON.stringify(temp));
        },
        selectedFilterShift(letter){
            if(letter === 'a') {
                this.showFilterShiftB = false
                this.showFilterShiftC = false;
            }
            if(letter === 'b') {
                this.showFilterShiftA = false
                this.showFilterShiftC = false;
            }
            if(letter === 'c') {
                this.showFilterShiftB = false
                this.showFilterShiftA = false;
            }

            let temp = JSON.parse(localStorage.getItem(process.env.VUE_APP_LOCAL_STORAGE_WORKING_MODES_VALUES));
            this.startHour = new Date(1970, 0, 1, temp[`shift_${letter}_start`], 0, 0, 0)
            this.endHour = new Date(1970, 0, 1, temp[`shift_${letter}_end`], 0, 0, 0)
        },
        updateLocalStorage(shift, val) {
            let temp = JSON.parse(localStorage.getItem(process.env.VUE_APP_LOCAL_STORAGE_WORKING_MODES_VALUES));
            temp[shift] = val;
            localStorage.setItem(process.env.VUE_APP_LOCAL_STORAGE_WORKING_MODES_VALUES, JSON.stringify(temp));
        },
        restoreData(){
            if(this.showFilterShiftA || this.showFilterShiftB || this.showFilterShiftC){
                return
            }

            this.setDataByOriginalResponse()
        },
        bgColorGetItemByArrayVehicles(val){
            if(val < 2){
                return false
            }

            let item = this.arrayVehicles[val];
            return item.workingSeconds === 0 &&
                item.idleSeconds === 0 &&
                item.maneuveringSeconds === 0 &&
                item.travelingSeconds === 0 &&
                item.waitingSeconds === 0 &&
                item.maintenanceSeconds === 0 &&
                item.inconsistencySeconds === 0;
        }
    },
    computed: {
        isScreenMobile: function() {
            return window.screen.width <= 1024;
        },
        arrayVehiclesName: function() {
            return this.arrayVehicles.map(element => element.name);
        },
        getItemByArrayVehicles: function(){
            return this.arrayVehicles[this.vehicleSelected];
        }
    },
    watch:{
        startHour: function(val){
            let date = new Date(val);
            this.tsStartHour = date.getHours();

            if (this.showFilterShiftA) {
                this.updateLocalStorage('shift_a_start', this.tsStartHour);
                return
            }
            if (this.showFilterShiftB) {
                this.updateLocalStorage('shift_b_start', this.tsStartHour);
                return
            }
            if (this.showFilterShiftC) {
                this.updateLocalStorage('shift_c_start', this.tsStartHour);
                return
            }
        },
        endHour: function(val){
            let date = new Date(val);
            this.tsEndHour = date.getHours();

            if (this.showFilterShiftA) {
                this.updateLocalStorage('shift_a_end', this.tsEndHour);
                return
            }
            if (this.showFilterShiftB) {
                this.updateLocalStorage('shift_b_end', this.tsEndHour);
                return
            }
            if (this.showFilterShiftC) {
                this.updateLocalStorage('shift_c_end', this.tsEndHour);
                return
            }
        },
        showFilterShiftA: function(val){
            if(val) {
                this.selectedFilterShift("a");
            }
            this.restoreData();
        },
        showFilterShiftB: function(val){
            if(val) {
                this.selectedFilterShift("b");
            }
            this.restoreData();
        },
        showFilterShiftC: function(val){
            if(val) {
                this.selectedFilterShift("c");
            }
            this.restoreData();
        },
        workingModeMoney: function(){
            this.setValuesToPay()
        },
        idleModeMoney: function(){
            this.setValuesToPay()
        },
        maneuveringModeMoney: function(){
            this.setValuesToPay()
        },
        travelingModeMoney: function(){
            this.setValuesToPay()
        },
        offModeMoney: function(){
            this.setValuesToPay()
        },
        waitingDataModeMoney: function(){
            this.setValuesToPay()
        },
        inconsistencyModeMoney: function(){
            this.setValuesToPay()
        },
        maintenanceModeMoney: function(){
            this.setValuesToPay()
        },
        consumptionMoney: function(){
            this.setValuesToPay()
        },
        showFilterShift: function(val){
            if(val.length === 0){
                this.showFilterShiftA = false
                this.showFilterShiftB = false
                this.showFilterShiftC = false
                this.showFilterShiftProp = ''
                return
            }

            if(val.length > 1){
                this.showFilterShift.shift()
            }

            if(this.showFilterShift[0] === "A"){
                this.showFilterShiftA = true
            }

            if(this.showFilterShift[0] === "B"){
                this.showFilterShiftB = true
            }

            if(this.showFilterShift[0] === "C"){
                this.showFilterShiftC = true
            }
        }
    },
    components: {
        Dialog,
        AppButton,
        AppFilterBar,
        AppWorkingModesLineChart,
        AppWorkingModesPie,
        AppWorkingModesInfoVehicle,
        ProgressSpinner,
        Calendar,
        Checkbox,
        Button,
        InputNumber,
        SelectButton
    },
}
</script>

<style scoped lang="scss">

.bg-vehicle-name{
    background-color: #cfcfcf !important;
    border: #cfcfcf 2px solid !important;
}

.container-config {
    height: 700px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.container-config-first {
    padding: 0 0 5px 0;
    width: 90%;
    height: 47.5%;
}

.container-config-last {
    display: flex;
    height: 47.5%;
    width: 90%;
}

.container-costs {
    background-color: white;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 5px 15px;
    height: 100%;
    display: flex;
    padding: 10px 10%;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
}

.container-shift {
    padding: 5px 5px 0 0;
    width: 50%;
}

.container-format {
    padding: 5px 0 0 5px;
    width: 50%;
}

.height-info, .height-info-total, .height-info-config{
    width: calc(100% - 80px);
    height: 740px;
    padding: 20px;
    margin: 0 0 0 40px;
    border: 2px solid rgb(180, 180, 180);
    border-top-right-radius: 20px;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    position: relative;
    bottom: 2px;
}

.icon-RS {
    height: 30px;
    width: 30px;
    margin: 0 7px;
    border-radius: 50%;
    background-color: #F3AD26;
    text-align: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
    color: white;
    border: 1px solid #FEE440;
    font-size: 12px;
    font-weight: bold;
    position: relative;
    bottom: 3px;
    padding-bottom: 1px;
}

.dashboard-container {
    height: 785px;
    width: 98%;
    padding: 20px;
    margin: auto;
    border: 2px solid rgb(180, 180, 180);
    border-top-right-radius: 20px;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
}

.width-vehicle-name{
    width: calc(100vw - 250px);
    margin: 40px 0 0 40px;
    display: flex;
    border-top-right-radius: 11px;
    border-top-left-radius: 11px;
    overflow-x: auto;
    white-space: nowrap;
}

.vehicle-name{
    border: 2px solid rgb(180, 180, 180);
    border-bottom: none;
    border-top-right-radius: 12px;
    border-top-left-radius: 12px;
    padding: 0 20px;
    height: 45px;
    text-align: center;
    align-items: center;
    justify-content: center;
    z-index: 2;
    display: flex;
    flex-direction: column;
    color: #F4F4F4;
    background-color: rgb(180, 180, 180);
    cursor: pointer;
}

.vehicle-name h4{
    position: relative;
    top: 2px;
    font-size: 17px;
}

.vehicle-name-active{
    border: 2px solid rgb(180, 180, 180);
    border-bottom: none;
    border-top-right-radius: 12px;
    border-top-left-radius: 12px;
    padding: 0 20px;
    height: 45px;
    text-align: center;
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
    background-color: #F4F4F4;
    color: #696969;
}

.vehicle-name-active h4{
    position: relative;
    top: 2px;
    font-size: 17px;
}

.f-bold{
    font-weight: bold;
}

.m-t-17{
    margin-top: 17px;
}

.m-t-13{
    margin-top: 13px;
}

.no-data-container{
    background-color: white;
    color: #696969;
    width: 80%;
    margin: 0 auto;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 5px 15px;
    height: 100px;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.no-data-container-after-filter{
    background-color: white;
    color: #696969;
    width: 80%;
    margin: 20px auto 20px auto;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 5px 15px;
    height: 100px;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.dashboard-container-no-data {
    width: 90%;
    margin: 30px auto 0 auto;
}

.dashboard-container-loading{
    height: calc(100vh - 81px);
    width: 80%;
    margin: 0 auto;
    text-align: center;
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
}

.input-money{
    width: 22%;
    margin: 0 10px;
    display: flex;
    flex-direction: column;
    text-align: center;
}

.container-input-money{
    height: 18%;
    padding-top: 11px;
    display: flex;
    flex-wrap: wrap;
}

h5{
    font-size: 15px
}

.menu-container{
    height: 250px;
    width: 90%;
    margin: 0 auto 30px auto;
}

.width-selected-shift{
    width: 33%;
    margin: 20px 0 0 11px;
}

.width-selected-hour{
    width: 33%;
    margin: 0 auto;
}

.width-export-csv{
    width: 33%;
    margin: auto;
}

.menu-shift{
    display: flex;
    height: 50%;
    color: #696969;
}

.button-filter-shift{
    font-size: 13px;
    float: right;
    margin-right: 11px;
    height: 35px;
}

.justify-selected-hour{
    display: flex
}

@media (min-width: 1399px) and (max-width: 1635px) {
    h6{
        font-size: 11px
    }

    h5{
        font-size: 13px
    }

    h4{
        font-size: 18px;
    }
}

@media (min-width: 1025px) and (max-width: 1398px) {
    .height-info{
        height: 930px;
        margin-bottom: 40px;
    }

    .height-info-total{
        height: 890px;
        margin-bottom: 40px;
    }

    .height-info-config{
        height: 750px;
        margin-bottom: 40px;
    }

    h6{
        font-size: 11px
    }

    h5{
        font-size: 13px
    }

    h4{
        font-size: 18px;
    }
}

@media (max-width: 1024px){

    .width-vehicle-name{
        width: calc(95vw - 30px) !important;
        margin: 15px 0 0 2.5vw;
    }

    .height-info{
        height: 1690px;
        width: 95vw;
        margin: 0 auto;
        padding: 10px;
    }

    .height-info-total{
        height: 1220px;
        width: 95vw;
        margin: 0 auto;
        padding: 10px;
    }

    .height-info-config{
        height: 870px;
        width: 95vw;
        margin: 0 auto;
        padding: 10px;
    }

    .menu-container{
        width: 95%;
        height: auto;
        margin-bottom: 10px;
    }

    .menu-shift{
        display: block;
        text-align: center;
        height: auto;
    }

    .width-selected-shift{
        margin: 0;
        width: 100%;
    }

    .width-selected-hour{
        margin: 20px auto 0 auto;
        width: 100%;
    }

    .justify-selected-hour{
        justify-content: center;
    }

    .width-export-csv{
        margin: 20px 0 0 0;
        padding-bottom: 50px;
        width: 100%;
        height: 35px;
    }

    .button-filter-shift{
        float: none;
        margin: 0;
    }

    h5{
        font-size: 14px
    }

    .input-money{
        width: 27%;
        margin: 0;
        display: flex;
        flex-direction: column;
    }

    .input-money > div:first-of-type {
        width: 20px !important;
        height: 3px;
    }

    .container-input-money{
        height: 18%;
        display: flex;
        flex-wrap: wrap;
    }

    .width-vehicle-name{
        width: 95%;
    }

    .container-config {
        height: auto;
    }

    .container-config-first {
        width: 100%;
        height: 350px;
    }

    .container-config-last {
        display: block;
        height: 600px;
        width: 100%;
    }

    .container-costs {
        background-color: white;
        border-radius: 10px;
        box-shadow: rgba(0, 0, 0, 0.1) 0px 5px 15px;
        height: 100%;
        display: flex;
        padding: 10px 0 0 0;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
    }

    .container-shift {
        padding: 5px 0;
        height: 300px;
        width: 100%;
    }

    .container-format {
        padding: 5px 0;
        height: 200px;
        width: 100%;
    }

}

@media (max-width: 500px) {

    .height-info{
        height: 1880px;
        width: 95vw;
        margin: 0 auto;
        padding: 10px;
    }

    .height-info-total{
        height: 1280px;
        width: 95vw;
        margin: 0 auto;
        padding: 10px;
    }

    .height-info-config{
        height: 870px;
        width: 95vw;
        margin: 0 auto;
        padding: 10px;
    }

}

</style>
